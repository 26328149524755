<template >
    <div>
        <portfolio/>
    </div>
</template>
<script>
import portfolio from '../components/portfolio.vue'
export default {
    name: 'PersonalView',
    components: {
        portfolio
    }
    
}
</script>
<style lang="">
    
</style>