<template lang="">
  <!-- TOP BAR -->
  <nav class="fixed w-full z-20 top-0 bg-[#BC4A00]">
    <div
      class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-3"
    >
      <div class="w-[40px] h-[40px] bg-white rounded-full">
        <p class="text-[25px] font-bold text-center text-[#E56F02]">J</p>
      </div>
      <button
        data-collapse-toggle="navbar-default"
        type="button"
        class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-default"
        aria-expanded="false"
      >
        <span class="sr-only">Open main menu</span>
        <svg
          class="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
      <div class="hidden w-full md:block md:w-auto" id="navbar-default">
        <ul
          class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-10 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-[#BC4A00] dark:border-gray-700"
        >
          <li>
            <a
              href="#"
              class="tracking-widest block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:font-bold md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              aria-current="page"
              @click="scrollToHome"
              >Home</a
            >
          </li>
          <li>
            <a
              href="#"
              class="tracking-widest block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:font-bold md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              @click.prevent="scrollToAbout"
              >About</a
            >
          </li>
          <li>
            <a
              href="#"
             class="tracking-widest block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:font-bold md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              @click.prevent="scrollToService"
              >Services</a
            >
          </li>
          <!-- <li>
            <a
              href="#"
              class="tracking-widest block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:font-bold md:p-0 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
              @click.prevent="scrollToReviews"
              >Reviews</a
            >
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
  <!-- END OF TOP BAR -->

  <!-- HOME CONTENT  -->
  <div id="home" class="h-screen hidden sm:block">
    <div
      class="max-w-screen md:h-[600px] h-[500px] bg-gradient-to-t from-[#562200] via-[#833300] to-[#BC4A00] hidden sm:block"
    >
      <div
        class="flex justify-center items-center md:top-[100px] absolute w-full space-x-10"
      >
        <div class="text-white font-bold md:text-[55px] text-[20px]">
          Hi, I'am Jayne Adil.
          <p class="text-[30px]">Executive Assistant, Project Manager</p>
         
          <p class="text-[20px] font-normal top-6 absolute relative">5 days Free Trial on my service</p>
          <button
            class="hover:scale-105 hover:shadow-lg py-2 px-5 text-[20px] bg-transparent border-2 border-white rounded-xl text-white font-medium tracking-wider"
          >
          <a  href='https://calendly.com/jayne-vahouse/va-house-corp-client-appointment?month=2024-06'>
          Get Started
        </a>
          </button>
        </div>
        <img
          src="../assets/img/jayne.png"
          alt="No Image"
          class="md:h-[500px] h-[200px] z-10"
        />
      </div>
    </div>
    <!-- END HOME  -->

    <!-- COMPANY  -->
    <div class="hidden sm:block">
      <div
        class="flex justify-center items-center font-semibold text-[25px] text-[#3F3F3F] mt-10"
      >
        Organizations I've Collaborated With
      </div>
      <div
        class="flex flex-row justify-center items-center space-x-[60px] w-full mt-[50px]"
      >
        <img
          src="../assets/img/wepropel.png"
          alt="WePropel"
          class="w-40 h-auto"
        />
        <img src="../assets/img/excess.png" alt="Excess" class="w-40 h-auto" />
        <img
          src="../assets/img/alorica.png"
          alt="Alorica"
          class="w-40 h-auto"
        />
        <img
          src="../assets/img/tmobile.png"
          alt="T-Mobile"
          class="w-40 h-auto"
        />
        <img src="../assets/img/acc.png" alt="Acc" class="w-40 h-auto" />
      </div>
    </div>
  </div>

  <!-- END COMPANY  -->


  <!-- ABOUT  -->
  <div id="about" class="h-auto hidden sm:block">
    <div class="hidden sm:block">
      <div class="relative">
        <!-- GRAY BG -->
        <div class="flex justify-center items-center space-x-10 w-full">
          <img
            src="../assets/img/jayne3.png"
            alt="No Image"
            class="w-[350px] h-[400px] top-[100px] absolute relative"
          />

          <!-- TEXT -->

          <div class="relative text-[45px] font-bold mt-[180px]">
            <span class="text-[#3F3F3F]">I’m Jayne Adil, I am skilled</span>
            <br />
            <span class="text-[#E56F02]"
              >Executive Assistant<span class="text-[#3F3F3F]">, </span></span
            >
            <br />
            <span class="text-[#E56F02]"
              >Project Manager<span class="text-[#3F3F3F]"> and </span>
              Recruiter</span
            >
            <!-- LINE -->
            <div class="bg-[#E56F02] w-[650px] h-[2px] rounded-full mt-5"></div>
            <!-- END LINE -->
            <div
              class="text-xl text-[#3F3F3F] font-normal w-[650px] mt-10 space-y-3"
            >
              <p class="text-justify">
                Hi, I am a skilled Virtual Assistant, Receptionist, Customer
                Support, and Project Manager.
              </p>
              <p class="text-justify">
                I have been in the business of helping business leaders like you
                from United States, United Kingdom, Australia and Canada for a
                decade now, and we are ready to help more people with the
                talents we have in our team.
              </p>
              <table class="table-auto w-full">
                <thead>
                  <tr class="font-bold text-[30px] text-[#3F3F3F] text-center">
                    <th class="px-4 py-2">300+</th>
                    <th class="px-4 py-2">17</th>
                    <th class="px-4 py-2">100+</th>
                    <th class="px-4 py-2">6+</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="font-semibold text-[15px] text-[#3F3F3F] text-center"
                  >
                    <td class="px-4 py-2">Project Completed</td>
                    <td class="px-4 py-2">Year of Experience</td>
                    <td class="px-4 py-2">Happy Clients</td>
                    <td class="px-4 py-2">
                      Collaborated <br />
                      Organizations
                    </td>
                  </tr>
                </tbody>
              </table>
              <div></div>
              <!-- END TEXT -->
            </div>
          </div>
        </div>
        <!-- END TABLE  -->
      </div>
    </div>
  </div>
  <!-- END ABOUT  -->

  <!-- SERVICE  -->
  <div id="service" class="h-screen space-y-5 hidden sm:block ">
    <div
      class="flex justify-center items-center font-semibold  text-[#3F3F3F]"
    >
      <div class="mt-[70px] text-[30px]">What We Can Do</div>
    </div>
    <!-- ROW 1  -->
    <div
      v-if="showFirst"
      class="flex justify-center items-center space-x-10 mt-5"
    >
      <!-- CARD 1  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/manage.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Project Management
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Strategic Coordination for Delivering Successful Outcomes.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="projectManage1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 1  -->

      <!-- CARD 2  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/assist.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Administrative & Executive Assistant
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Streamlining Operations and Enhancing Executive Support.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="adminAss1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 2  -->
      <!-- CARD 3  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/chat.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Customer Service Support
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Delivering Exceptional Assistance and Ensuring Customer Satisfaction.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="customerSup1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 3  -->
    </div>
    <!-- END OF ROW 1  -->

    <!-- ROW 2  -->
    <div
      v-if="showFirst"
      class="flex justify-center items-center space-x-10"
      :class="{ 'transition-down': scrolled }"
    >
      <!-- CARD 1  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/email.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Chat/Email Support
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Instant Assistance for Your Queries and Concerns via Chat or Email.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="chatSupp1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 1  -->

      <!-- CARD 2  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/lead.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Lead Generation
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Unlocking New Business Opportunities with Targeted Lead Generation.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="leadGen1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 2  -->
      <!-- CARD 3  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/sales.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Sales
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Driving Revenue Growth Through Strategic Sales Solutions.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="Sales1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 3  -->
    </div>
    <!-- END OF ROW 2  -->

    <!-- SECOND DRAWER  -->
    <!-- ROW 1  -->
    <div
      v-if="!showSecond"
      class="flex justify-center items-center space-x-10"
      :class="{ 'transition-down': scrolled }"
    >
      <!-- CARD 1  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/media.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Social Media Management
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Enhancing Your Brand's Presence and Engagement Across Social Media
          Platforms.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="socialMed1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 1  -->

      <!-- CARD 2  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/market.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Marketing
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Crafting Innovative Strategies to Elevate Your Brand and Drive
          Success.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="marketing1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 2  -->
      <!-- CARD 3  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/book.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Bookeping/Accounting
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Ensuring Accurate Financial Records and Insights for Your Business
          Growth.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="bookeeping1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 3  -->
    </div>
    <!-- END OF ROW 1  -->

    <!-- ROW 2  -->
    <div
      v-if="!showSecond"
      class="flex justify-center items-center space-x-10"
      :class="{ 'transition-down': scrolled }"
    >
      <!-- CARD 1  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/transaction.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Transaction Coordinator
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Seamlessly Managing Every Step of Your Real Estate Transactions.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="transactionCoor1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 1  -->

      <!-- CARD 2  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/data.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Data Entry
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Accurate and Efficient Data Entry Services for Your Business Needs.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="dataEn1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 2  -->
      <!-- CARD 3  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/search.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Research
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Delivering In-Depth Insights and Analysis to Inform Your Decisions.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="research1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 3  -->
    </div>
    <!-- END OF ROW 2  -->

    <!-- THIRD DRAWER  -->
    <!-- ROW 1  -->
    <div
      v-if="!showThird"
      class="flex justify-center items-center space-x-10"
      :class="{ 'transition-down': scrolled }"
    >
      <!-- CARD 1  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/human.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Human Resource
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Optimizing Talent Management and Workforce Solutions for Your
          Business.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="humanResource1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 1  -->

      <!-- CARD 2  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/script.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Transcription
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Transforming Audio into Accurate Text for Seamless Communication and
          Documentation.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="transcription1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 2  -->
      <!-- CARD 3  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/webDev.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Web Development
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Crafting Dynamic Digital Experiences Tailored to Your Unique Vision
          and Goals.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="webDev1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 3  -->
    </div>
    <!-- END OF ROW 2  -->

    <!-- ROW 1  -->
    <div
      v-if="!showThird"
      class="flex justify-center items-center space-x-10"
      :class="{ 'transition-down': scrolled }"
    >
      <!-- CARD 1  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/web.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          Web Design
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Bringing Your Vision to Life Through Stunning and User-Friendly Web
          Design Solutions.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="webDes1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 1  -->

      <!-- CARD 2  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/system.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          System Administration
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Streamlining Operations and Ensuring Peak Performance Through Expert
          System Administration.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="systemAdmin1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 2  -->
      <!-- CARD 3  -->
      <div
        class="bg-white w-[480px] h-[330px] rounded-[20px] shadow hover:shadow-lg transition-transform transform hover:scale-105"
      >
        <div class="top-[50px] left-[50px] absolute">
          <button>
            <img src="../assets/img/lms.png" alt="" class="w-10 h-10" />
          </button>
        </div>
        <div
          class="top-[80px] absolute text-[#3F3F3F] text-xl font-semibold m-10"
        >
          LMS Administration
        </div>
        <div
          class="top-[120px] absolute text-[#3F3F3F] text-md font-semibold m-10"
        >
          Empowering Seamless Learning Experiences Through Expert Management of
          Your Learning Management System.
        </div>

        <div class="top-[270px] right-[50px] absolute">
          <button @click="lmsAdmin1">
            <img src="../assets/img/more.png" alt="" class="w-10 h-10" />
          </button>
        </div>
      </div>
      <!-- END OF CARD 3  -->
    </div>
    <!-- END OF ROW 2  -->

    <div class="flex justify-center items-center space-x-5 top-10 absolute relative">
      <span
        class="w-4 h-4 bg-[#D9D9D9] rounded-full transition-transform transform hover:scale-105 hover:bg-gray-600 cursor-pointer"
        :class="{
          'bg-gray-600': activeTab === 1,
          'bg-[#D9D9D9]': activeTab !== 1,
        }"
        @click="activateTab1(1)"
      ></span>
      <span
        class="w-4 h-4 bg-[#D9D9D9] rounded-full transition-transform transform hover:scale-105 hover:bg-gray-600 cursor-pointer"
        :class="{
          'bg-gray-600': activeTab === 2,
          'bg-[#D9D9D9]': activeTab !== 2,
        }"
        @click="activateTab2(2)"
      ></span>
      <span
        class="w-4 h-4 bg-[#D9D9D9] rounded-full transition-transform transform hover:scale-105 hover:bg-gray-600 cursor-pointer"
        :class="{
          'bg-gray-600': activeTab === 3,
          'bg-[#D9D9D9]': activeTab !== 3,
        }"
        @click="activateTab3(3)"
      ></span>
    </div>
  </div>

  <!-- END OF SERVICE  -->
  <!-- DETAILS  -->
  <!-- PROJECT MANAGEMENT  -->
  <div class="hidden sm:block">
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="project1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="project1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Project Management</div>
            <div class="text-[20px] font-normal mt-5">
              Strategic Coordination for Delivering Successful Outcomes.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img src="../assets/img/trello.png" alt="" class="h-10 mt-5" />
              <img src="../assets/img/asana.png" alt="" class="h-7 mt-[30px]" />
              <img src="../assets/img/jira.png" alt="" class="h-9 mt-[30px]" />
              <img
                src="../assets/img/monday.png"
                alt=""
                class="h-9 mt-[30px]"
              />
              <img src="../assets/img/zoho.png" alt="" class="h-9 mt-[30px]" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Administrative & Executive Assistant  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="admin1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="admin1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">
              Administrative & Executive Assistant
            </div>
            <div class="text-[20px] font-normal mt-5">
              Streamlining Operations and Enhancing Executive Support.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img src="../assets/img/outlook.png" alt="" class="h-10 mt-5" />
              <img
                src="../assets/img/google.png"
                alt=""
                class="h-7 mt-[30px]"
              />
              <img src="../assets/img/slack.png" alt="" class="h-9 mt-[30px]" />
              <img src="../assets/img/zoom.png" alt="" class="h-9 mt-[30px]" />
              <img
                src="../assets/img/notion.png"
                alt=""
                class="h-9 mt-[30px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Customer Service Support -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="customer1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="customer1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">
              Customer Service Support
            </div>
            <div class="text-[20px] font-normal mt-5">
              Delivering Exceptional Assistance and Ensuring Customer
              Satisfaction.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img src="../assets/img/fresh.png" alt="" class="h-10 mt-8" />
              <img src="../assets/img/hub.png" alt="" class="h-7 mt-[30px]" />
              <img src="../assets/img/live.png" alt="" class="h-9 mt-[30px]" />
              <img
                src="../assets/img/salesforce.png"
                alt=""
                class="h-9 mt-[30px]"
              />
              <img src="../assets/img/dial.png" alt="" class="h-9 mt-[30px]" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Chat/Email Support  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="chat1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="chat1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Chat/Email Support</div>
            <div class="text-[20px] font-normal mt-5">
              Instant Assistance for Your Queries and Concerns via Chat or
              Email.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img src="../assets/img/fresh.png" alt="" class="h-10 mt-8" />
              <img src="../assets/img/hub.png" alt="" class="h-7 mt-[30px]" />
              <img src="../assets/img/live.png" alt="" class="h-9 mt-[30px]" />
              <img
                src="../assets/img/salesforce.png"
                alt=""
                class="h-9 mt-[30px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Lead Generation  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="lead1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="lead1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Lead Generation</div>
            <div class="text-[20px] font-normal mt-5">
              Unlocking New Business Opportunities with Targeted Lead
              Generation.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img
                src="../assets/img/salesforce.png"
                alt=""
                class="h-10 mt-8"
              />
              <img src="../assets/img/hub.png" alt="" class="h-7 mt-[30px]" />
              <img
                src="../assets/img/convert.svg"
                alt=""
                class="w-32 h-9 mt-[30px]"
              />
              <img src="../assets/img/link.png" alt="" class="h-9 mt-[30px]" />
              <img
                src="../assets/img/leadPage.png"
                alt=""
                class="h-9 mt-[30px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Sale  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="sales1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="sales1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Sales</div>
            <div class="text-[20px] font-normal mt-5">
              Driving Revenue Growth Through Strategic Sales Solutions.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img src="../assets/img/out.png" alt="" class="w-32 h-8 mt-8" />
              <img src="../assets/img/hub.png" alt="" class="h-7 mt-[30px]" />
              <img src="../assets/img/zoho.png" alt="" class="h-9 mt-[30px]" />
              <img
                src="../assets/img/salesforce.png"
                alt=""
                class="h-9 mt-[30px]"
              />
              <img
                src="../assets/img/salesSoft.png"
                alt=""
                class="h-9 mt-[30px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SOCIAL MEDIA MANAGEMENT  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="social1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="social1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Social Media Management</div>
            <div class="text-[20px] font-normal mt-5">
              Enhancing Your Brand's Presence and Engagement Across Social Media
              Platforms.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img src="../assets/img/hoot.png" alt="" class="w-32 h-8 mt-8" />
              <img
                src="../assets/img/buffer.png"
                alt=""
                class="h-7 mt-[30px]"
              />
              <img
                src="../assets/img/sprout.png"
                alt=""
                class="h-9 mt-[30px]"
              />
              <img src="../assets/img/later.png" alt="" class="h-9 mt-[30px]" />
              <img src="../assets/img/bee.png" alt="" class="h-9 mt-[30px]" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Marketing  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="market1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="market1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Marketing</div>
            <div class="text-[20px] font-normal mt-5">
              Crafting Innovative Strategies to Elevate Your Brand and Drive
              Success.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img src="../assets/img/hub.png" alt="" class="w-32 h-8 mt-8" />
              <img
                src="../assets/img/mailchamp.png"
                alt=""
                class="h-7 mt-[30px]"
              />
              <img src="../assets/img/sem.png" alt="" class="h-9 mt-[30px]" />
              <img
                src="../assets/img/marketo.png"
                alt=""
                class="h-9 mt-[30px]"
              />
              <img
                src="../assets/img/googleAn.png"
                alt=""
                class="h-9 mt-[30px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Bookeping/Accounting  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="book1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="book1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Bookeping/Accounting</div>
            <div class="text-[20px] font-normal mt-5">
              Ensuring Accurate Financial Records and Insights for Your Business
              Growth.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img src="../assets/img/quick.png" alt="" class="w-32 h-8 mt-8" />
              <img src="../assets/img/xero.png" alt="" class="h-7 mt-[30px]" />
              <img
                src="../assets/img/freshbook.png"
                alt=""
                class="h-9 mt-[30px]"
              />
              <img src="../assets/img/wave.png" alt="" class="h-9 mt-[30px]" />
              <img src="../assets/img/zoho.png" alt="" class="h-9 mt-[30px]" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Transaction Coordinator  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="transaction1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="transaction1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Transaction Coordinator</div>
            <div class="text-[20px] font-normal mt-5">
              Seamlessly Managing Every Step of Your Real Estate Transactions.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img src="../assets/img/dot.png" alt="" class="w-20 h-8 mt-8" />
              <img src="../assets/img/sky.png" alt="" class="h-7 mt-[30px]" />
              <img
                src="../assets/img/broker.png"
                alt=""
                class="h-9 mt-[30px]"
              />
              <img
                src="../assets/img/trello.png"
                alt=""
                class="h-9 mt-[30px]"
              />
              <img src="../assets/img/tc.png" alt="" class="h-9 mt-[30px]" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Data Entry  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="dataEntry1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="dataEntry1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Data Entry</div>
            <div class="text-[20px] font-normal mt-5">
              Accurate and Efficient Data Entry Services for Your Business
              Needs.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img src="../assets/img/excel.png" alt="" class="w-10 h-8 mt-8" />
              <img
                src="../assets/img/sheets.png"
                alt=""
                class="h-7 mt-[30px]"
              />
              <img src="../assets/img/air.png" alt="" class="h-9 mt-[30px]" />
              <img src="../assets/img/forms.png" alt="" class="h-9 mt-[30px]" />
              <img src="../assets/img/smart.png" alt="" class="h-9 mt-[30px]" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Research  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="search1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="search1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Research</div>
            <div class="text-[20px] font-normal mt-5">
              Delivering In-Depth Insights and Analysis to Inform Your
              Decisions.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img
                src="../assets/img/zotero.png"
                alt=""
                class="w-32 h-8 mt-8"
              />
              <img src="../assets/img/med.png" alt="" class="h-7 mt-[30px]" />
              <img src="../assets/img/ever.png" alt="" class="h-9 mt-[30px]" />
              <img src="../assets/img/scri.png" alt="" class="h-9 mt-[30px]" />
              <img
                src="../assets/img/scholar.png"
                alt=""
                class="h-9 mt-[30px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Human Resource  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="human1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="human1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Human Resource</div>
            <div class="text-[20px] font-normal mt-5">
              Optimizing Talent Management and Workforce Solutions for Your
              Business.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
          </div>
        </div>
      </div>
    </div>

    <!-- Transcriptions  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="trans1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="trans1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Transcriptions</div>
            <div class="text-[20px] font-normal mt-5">
              Transforming Audio into Accurate Text for Seamless Communication
              and Documentation.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img src="../assets/img/otter.png" alt="" class="w-32 h-8 mt-8" />
              <img src="../assets/img/rev.png" alt="" class="h-7 mt-[30px]" />
              <img
                src="../assets/img/descript.png"
                alt=""
                class="h-9 mt-[30px]"
              />
              <img src="../assets/img/temi.jpg" alt="" class="h-9 mt-[30px]" />
              <img src="../assets/img/trint.png" alt="" class="h-9 mt-[30px]" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Web Development  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="webDevelop1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="webDevelop1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Web Development</div>
            <div class="text-[20px] font-normal mt-5">
              Crafting Dynamic Digital Experiences Tailored to Your Unique
              Vision and Goals.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img src="../assets/img/react.png" alt="" class="h-8 mt-8" />
              <img src="../assets/img/vue.png" alt="" class="h-7 mt-[30px]" />
              <img src="../assets/img/php.png" alt="" class="h-9 mt-[30px]" />
              <img src="../assets/img/nest.png" alt="" class="h-9 mt-[30px]" />
              <img
                src="../assets/img/laravel.png"
                alt=""
                class="h-9 mt-[30px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Web Design  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="webDesign1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="webDesign1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">Web Design</div>
            <div class="text-[20px] font-normal mt-5">
              Bringing Your Vision to Life Through Stunning and User-Friendly
              Web Design Solutions.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img src="../assets/img/figma.png" alt="" class="h-8 mt-8" />
              <img
                src="../assets/img/sketch.png"
                alt=""
                class="h-7 mt-[30px]"
              />
              <img
                src="../assets/img/webflow.svg"
                alt=""
                class="h-9 mt-[30px]"
              />
              <img src="../assets/img/nest.png" alt="" class="h-9 mt-[30px]" />
              <img
                src="../assets/img/invision.png"
                alt=""
                class="h-9 mt-[30px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- System Administration  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="systemAd1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="systemAd1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">System Administration</div>
            <div class="text-[20px] font-normal mt-5">
              Streamlining Operations and Ensuring Peak Performance Through
              Expert System Administration.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img
                src="../assets/img/docker.png"
                alt=""
                class="w-32 h-20 mt-8"
              />
              <img src="../assets/img/kuber.png" alt="" class="h-7 mt-[30px]" />
              <img
                src="../assets/img/amazon.png"
                alt=""
                class="h-9 mt-[30px]"
              />
              <img
                src="../assets/img/ansible.png"
                alt=""
                class="h-9 mt-[30px]"
              />
              <img src="../assets/img/git.png" alt="" class="h-9 mt-[30px]" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- LMS Administration  -->
    <div
      class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105"
      v-if="lmsAd1"
    >
      <div class="flex justify-center items-center h-screen relative">
        <div
          class="bg-white w-[1000px] h-[400px] fixed top-[150px] rounded-[20px]"
        >
          <div class="top-[25px] right-[25px] absolute">
            <button @click="lmsAd1 = false">
              <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
            </button>
          </div>
          <div class="m-10">
            <div class="text-[25px] font-semibold">LMS Administration</div>
            <div class="text-[20px] font-normal mt-5">
              Empowering Seamless Learning Experiences Through Expert Management
              of Your Learning Management System.
            </div>
            <div class="text-[20px] font-normal mt-[100px]">Tools Used:</div>
            <!-- IMAGES LOGO  -->
            <div class="flex flex-row justify-center items-center space-x-10">
              <img
                src="../assets/img/moodle.png"
                alt=""
                class="w-32 h-10 mt-8"
              />
              <img
                src="../assets/img/canvas.png"
                alt=""
                class="h-7 mt-[30px]"
              />
              <img src="../assets/img/blac.png" alt="" class="h-9 mt-[30px]" />
              <img
                src="../assets/img/talents.png"
                alt=""
                class="h-9 mt-[30px]"
              />
              <img src="../assets/img/cast.png" alt="" class="h-9 mt-[30px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- END OF DETAILS  -->

  <!-- COLLABORATE MED DEVICE  -->
  <div class="bg-[#E56F02] w-full h-auto hidden sm:block mt-[100px]">
    <div class="flex justify-center items-center space-x-10 p-10">
      <div>
        <div class="text-white text-[30px] font-bold">
          Let’s Collaborate Together!
        </div>
        <div class="text-white text-lg font-normal mt-3">
         I offer 5 days Free Trial on my Services.
        </div>
      </div>
      <div>
        <button
          class="border-2 border-white w-[100px] h-[40px] text-white bg-transparent rounded-lg hover:scale-105"
        >
          Get Started
        </button>
      </div>
    </div>
  </div>
  <!-- END COLLABORATE  -->

  <!-- REVIEWS  -->
  <!-- <div id="reviews" class="hidden sm:block h-screen">
    <div
      class="flex justify-center items-center font-semibold text-[20px] text-[#3F3F3F]"
    >
    <div class="mt-[100px]">Client Reviews</div>
    </div>

    <div class="flex justify-center items-center">
      <div class="bg-[#F0F0F0] rounded-xl shadow-xl mt-10 p-10">
        <div class="flex justify-center items-center space-x-10">
          <img
            src="../assets/img/jayne4.png"
            alt="No Image"
            class="h-[400px] object-cover"
          />
          <div class="italic font-semibold text-[25px]" v-if="review1">
            "COMMENT 1 sdsadasdsaadsd sadasd"
          </div>
          <div class="italic font-semibold text-[25px]" v-if="!review2">
            "COMMENT 2 sddsadw sadsadsadsaddsadasdsadsa"
          </div>
          <div class="italic font-semibold text-[25px]" v-if="!review3">
            "COMMENT 3 dasdc dsdsa d 1421412412 "
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center items-center space-x-5 mt-4">
      <span
        class="w-4 h-4 rounded-full transition-transform transform hover:scale-105 cursor-pointer"
        :class="{
          'bg-gray-600': activeTabReview === 1,
          'bg-[#D9D9D9]': activeTabReview !== 1,
        }"
        @click="activeReviews1(1)"
      ></span>
      <span
        class="w-4 h-4 rounded-full transition-transform transform hover:scale-105 cursor-pointer"
        :class="{
          'bg-gray-600': activeTabReview === 2,
          'bg-[#D9D9D9]': activeTabReview !== 2,
        }"
        @click="activeReviews2(2)"
      ></span>
      <span
        class="w-4 h-4 rounded-full transition-transform transform hover:scale-105 cursor-pointer"
        :class="{
          'bg-gray-600': activeTabReview === 3,
          'bg-[#D9D9D9]': activeTabReview !== 3,
        }"
        @click="activeReviews3(3)"
      ></span>
    </div>
    <div class="relative w-full h-[100px] hidden sm:block mt-20">
      <img
        src="../assets/img/footer.png"
        alt="No Image"
        class="w-full h-full object-cover"
      />
      <div
        class="absolute inset-0 flex items-center justify-center text-white text-lg"
      >
        My Portfolio - Designed by Jayne Adil. All Rights Reserved.
      </div>
    </div>
  </div> -->

  <!-- END REVIEWS  -->

  <!-- RESPONSIVE HOME  -->
  <div
    class="max-w-screen h-[420px] bg-gradient-to-t from-[#562200] via-[#833300] to-[#BC4A00] block md:hidden"
  >
    <div
      class="text-[30px] absolute top-[100px] flex justify-center items-center w-full"
    >
      <h1 class="text-white font-bold">
        Hi, I'am Jayne Adil
        <p class="text-[12px] font-normal tracking-wider text-center">
          Executive Assistant, Project Manager
        </p>
      </h1>
    </div>
    <div class="flex justify-center items-center w-full top-[170px] absolute">
      <img
        src="../assets/img/jayne.png"
        alt="No Image"
        class="h-[250px] z-10"
      />
    </div>
  </div>

  <div class="block md:hidden">
    <div
      class="flex justify-center items-center mt-[15px] font-semibold text-[15px] text-[#3F3F3F]"
    >
      Organizations I've Collaborated With
    </div>

    <!-- IMAGES  -->
    <div id="app">
      <div class="flex flex-row overflow-x-hidden h-10 mt-3">
        <div class="flex flex-row space-x-10" :style="carouselStyle">
          <img
            v-for="(image, index) in images"
            :key="index"
            :src="image"
            class="carousel-item"
          />
        </div>
      </div>
    </div>

    <div class="absolute top-[530px] w-full flex justify-center items-center">
      <img src="../assets/img/jayne3.png" alt="No Image" class="h-[300px]" />
    </div>
    <div class="text-xl font-bold text-center mt-[350px]">
      <span class="text-[#3F3F3F]">I’m Jayne Adil, I am skilled</span><br />
      <span class="text-[#E56F02]"
        >Executive Assistant<span class="text-[#3F3F3F]">, </span>Project</span
      ><br />
      <span class="text-[#E56F02]"
        >Manager <span class="text-[#3F3F3F]">and </span> Recruiter</span
      >
    </div>
    <div class="w-full flex justify-center items-center mt-5">
      <div class="bg-[#E56F02] w-[200px] h-[2px] rounded-full"></div>
    </div>

    <div class="text-md text-[#3F3F3F]  mt-5 m-5 text-center">
      <span class="text-center font-semibold">
        I offer 5 days Free Trial on my Services
      </span>
      <span class="text-center"
        ><br />
        <br />
        I have been in the business of helping business leaders like you from
        United States, United Kingdom, Australia and Canada for a decade now,
        and we are ready to help more people with the talents we have in our
        team.
      </span>
      <div class="flex justify-center items-center flex-col ">
        <table class="table-auto text-[10px] mt-5 w-[350px]">
          <thead>
            <tr class=" text-[#3F3F3F] font-bold text-center text-xl">
              <th class="">300+</th>
              <th>17</th>

            </tr>
          </thead>
          <tbody>
            <tr class="font-semibold text-[#3F3F3F] text-center text-[15px]">
              <td>Project Completed</td>
              <td>Year of Experience</td>

            </tr>
          </tbody>
        </table>
        <table class="table-auto text-[10px] mt-5 w-[350px] text-xl">
          <thead>
            <tr class=" text-[#3F3F3F] text-center">
       
              <th>100+</th>
              <th>6+</th>
            </tr>
          </thead>
          <tbody>
            <tr class="font-semibold text-[#3F3F3F] text-center  text-[15px]">
     
              <td>Happy Clients</td>
              <td>
                Collaborated <br />
                Organizations
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      class="flex justify-center items-center mt-[10px] font-semibold text-lg  text-[#3F3F3F]"
    >
      What we can do
    </div>

    <div class="flex flex-row overflow-x-auto">
      <div class="flex justify-center items-center mt-5 space-x-5 m-5">
        <!-- CARD 1  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/manage.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Project Management
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Strategic Coordination for Delivering Successful Outcomes.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="projectManage">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- CARD 2  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/assist.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[50px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Administrative & Executive Assistant
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Streamlining Operations and Enhancing Executive Support.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="adminAss">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 2  -->
        <!-- CARD 3  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/chat.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Customer Service Support
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Delivering Exceptional Assistance and Ensuring Customer
            Satisfaction.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="customerSup">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 3  -->
        <!-- CARD 4  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/email.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Chat/Email Support
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Instant Assistance for Your Queries and Concerns via Chat or Email.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="chatSupp">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 4  -->
        <!-- CARD 5  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/lead.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Lead Generation
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Unlocking New Business Opportunities with Targeted Lead Generation.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="leadGen">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 5  -->
        <!-- CARD 6  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/sales.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Sales
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Driving Revenue Growth Through Strategic Sales Solutions.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="Sales">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 6  -->
        <!-- CARD 7  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/media.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Social Media Management
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Enhancing Your Brand's Presence and Engagement Across Social Media
            Platforms.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="socialMed">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 7  -->
        <!-- CARD 8  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/market.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Marketing
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Crafting Innovative Strategies to Elevate Your Brand and Drive
            Success.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="marketing">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 8  -->
        <!-- CARD 9  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/book.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Bookeping/Accounting
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Ensuring Accurate Financial Records and Insights for Your Business
            Growth.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="bookeeping">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 9  -->
        <!-- CARD 10  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/transaction.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Transaction Coordinator
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Seamlessly Managing Every Step of Your Real Estate Transactions.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="transactionCoor">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 10  -->
        <!-- CARD 11  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/data.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Data Entry
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Accurate and Efficient Data Entry Services for Your Business Needs.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="dataEn">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 11  -->
        <!-- CARD 12  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/search.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Research
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Delivering In-Depth Insights and Analysis to Inform Your Decisions.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="research">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 12  -->
        <!-- CARD 13  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/human.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Human Resource
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Optimizing Talent Management and Workforce Solutions for Your
            Business.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="humanResource">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 13  -->
        <!-- CARD 14  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/script.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Transcription
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Transforming Audio into Accurate Text for Seamless Communication and
            Documentation.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="transcription">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 14  -->
        <!-- CARD 15  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/webDev.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Web Development
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Crafting Dynamic Digital Experiences Tailored to Your Unique Vision
            and Goals.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="webDev">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 15  -->
        <!-- CARD 16  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/web.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            Web Design
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Bringing Your Vision to Life Through Stunning and User-Friendly Web
            Design Solutions.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="webDes">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 16  -->
        <!-- CARD 17  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/system.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            System Administration
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Streamlining Operations and Ensuring Peak Performance Through Expert
            System Administration.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="systemAdmin">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 17  -->
        <!-- CARD 18  -->
        <div
          class="bg-white w-[300px] h-[230px] rounded-lg border shadow-md hover:shadow-xl transition-transform transform hover:scale-105"
        >
          <div class="top-[10px] m-5 absolute">
            <button>
              <img src="../assets/img/lms.png" alt="" class="w-8 h-8" />
            </button>
          </div>
          <div
            class="top-[60px] absolute text-[#3F3F3F] text-[18px] font-semibold m-5"
          >
            LMS Administration
          </div>
          <div
            class="top-[100px] absolute text-[#3F3F3F] text-[13px] font-semibold m-5"
          >
            Empowering Seamless Learning Experiences Through Expert Management
            of Your Learning Management System.
          </div>

          <div class="top-[160px] right-0 m-5 absolute">
            <button @click="lmsAdmin">
              <img src="../assets/img/more.png" alt="" class="w-8 h-8" />
            </button>
          </div>
        </div>

        <!-- END OF CARD 18  -->
      </div>
    </div>
  </div>
  <!-- COLLABORATE CONTENT  -->
  <div class="max-w-screen h-[145px] bg-[#E56F02] block md:hidden mt-2">
    <div
      class="flex flex-col space-y-3 w-full justify-center items-center h-full text-white font-bold text-[18px]"
    >
      <h1 class=" ml-4">Let’s Collaborate Together!</h1>
      <div class="text-white text-sm font-normal text-center">
         I offer 5 days Free Trial on my Services.
        </div>
      <button class="border-2 rounded-lg w-24 h-10 text-[12px] tracking-wider">
        Get Started
      </button>
    </div>
  </div>

  <div></div>
  <!-- <div
    class="flex justify-center items-center mt-[15px] font-semibold text-[15px] text-[#3F3F3F] md:hidden mt-2"
  >
    Reviews from my clients
  </div>

  <div class="md:hidden m-3">
    <div class="bg-gray-200 w-full h-auto rounded-md mt-3">
      <div class="top-2 relative absolute">
        <div class="text-center">
          <p class="mt-2">"Jayne is a superb Leader and she is amazing."</p>
        </div>
      </div>
      <div class="ml-4 mt-4 text-sm">
        <p>John Doe</p>
        <p>CEO COMPANY</p>
      </div>
    </div>
  </div> -->

  <!-- CARD DETAILS RESPONSIVE -->
  <!-- PROJECT MANAGEMENT  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="project"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="project = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">
            Project Management
          </div>
          <div class="text-[15px] font-normal mt-5">
            Strategic Coordination for Delivering Successful Outcomes.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/trello.png" alt="" class="h-5" />
            <img src="../assets/img/asana.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/jira.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/monday.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/zoho.png" alt="" class="h-5 mt-[30px]" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Administrative & Executive  Assistant  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="admin"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="admin = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">
            Administrative & Executive Assistant
          </div>
          <div class="text-[15px] font-normal mt-5">
            Streamlining Operations and Enhancing Executive Support.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/outlook.png" alt="" class="h-5" />
            <img src="../assets/img/google.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/slack.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/zoom.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/notion.png" alt="" class="h-5 mt-[30px]" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Customer Service Support -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="customer"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="customer = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">
            Customer Service Support
          </div>
          <div class="text-[15px] font-normal mt-5">
            Delivering Exceptional Assistance and Ensuring Customer
            Satisfaction.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/fresh.png" alt="" class="h-5" />
            <img src="../assets/img/hub.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/live.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/salesforce.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/dial.png" alt="" class="h-5 mt-[30px]" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Chat/Email Support -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="chat"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="chat = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">
            Chat/Email Support
          </div>
          <div class="text-[15px] font-normal mt-5">
            Instant Assistance for Your Queries and Concerns via Chat or Email.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/fresh.png" alt="" class="h-5" />
            <img src="../assets/img/hub.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/live.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/salesforce.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Lead Generation -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="lead"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="lead = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">Lead Generation</div>
          <div class="text-[15px] font-normal mt-5">
            Unlocking New Business Opportunities with Targeted Lead Generation.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/convert.svg" alt="" class="h-5" />
            <img src="../assets/img/link.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/live.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/salesforce.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/leadPage.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Sale -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="sales"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="sales = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">Sales</div>
          <div class="text-[15px] font-normal mt-5">
            Driving Revenue Growth Through Strategic Sales Solutions.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/out.png" alt="" class="h-5" />
            <img src="../assets/img/hub.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/zoho.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/salesforce.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/salesSoft.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- SOCIAL MEDIA MANAGEMENT  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="social"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="social = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">
            Social Media Management
          </div>
          <div class="text-[15px] font-normal mt-5">
            Enhancing Your Brand's Presence and Engagement Across Social Media
            Platforms.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/hoot.png" alt="" class="h-5" />
            <img src="../assets/img/buffer.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/sprout.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/later.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/bee.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Marketing  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="market"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="market = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">Marketing</div>
          <div class="text-[15px] font-normal mt-5">
            Crafting Innovative Strategies to Elevate Your Brand and Drive
            Success.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/hub.png" alt="" class="h-5" />
            <img src="../assets/img/mailchamp.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/sem.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/marketo.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/googleAn.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Bookeping/Accounting  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="book"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="book = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">
            Bookeping/Accounting
          </div>
          <div class="text-[15px] font-normal mt-5">
            Ensuring Accurate Financial Records and Insights for Your Business
            Growth.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/quick.png" alt="" class="h-5" />
            <img src="../assets/img/xero.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/freshbook.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/wave.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/zoho.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Transaction Coordinator  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="transaction"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="transaction = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">
            Transaction Coordinator
          </div>
          <div class="text-[15px] font-normal mt-5">
            Seamlessly Managing Every Step of Your Real Estate Transactions.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/dot.png" alt="" class="h-5" />
            <img src="../assets/img/sky.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/broker.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/trello.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/tc.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Data Entry  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="dataEntry"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="dataEntry = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">Data Entry</div>
          <div class="text-[15px] font-normal mt-5">
            Accurate and Efficient Data Entry Services for Your Business Needs.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/excel.png" alt="" class="h-5" />
            <img src="../assets/img/sheets.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/air.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/forms.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/smart.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Research  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="search"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="search = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">Research</div>
          <div class="text-[15px] font-normal mt-5">
            Delivering In-Depth Insights and Analysis to Inform Your Decisions.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/zotero.png" alt="" class="h-5" />
            <img src="../assets/img/med.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/ever.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/scri.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/scholar.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Human Resource  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="human"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="human = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">Human Resource</div>
          <div class="text-[15px] font-normal mt-5">
            Optimizing Talent Management and Workforce Solutions for Your
            Business.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <!-- <div class=" columns-3  space-x-4 space-y-4 mt-5">
           
              <img src="../assets/img/zotero.png" alt="" class="h-5" />
              <img src="../assets/img/med.png" alt="" class="h-3 mt-5" />
              <img src="../assets/img/ever.png" alt="" class="h-5 mt-10" />
              <img src="../assets/img/scri.png" alt="" class="h-5 mt-5" />
              <img src="../assets/img/scholar.png" alt="" class="h-5 mt-5" />
           
            </div> -->
        </div>
      </div>
    </div>
  </div>

  <!-- Transcriptions  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="trans"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="trans = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">Transcriptions</div>
          <div class="text-[15px] font-normal mt-5">
            Transforming Audio into Accurate Text for Seamless Communication and
            Documentation.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/otter.png" alt="" class="h-5" />
            <img src="../assets/img/rev.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/descript.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/temi.jpg" alt="" class="h-5 mt-5" />
            <img src="../assets/img/trint.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Web Development  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="webDevelop"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="webDevelop = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">Web Development</div>
          <div class="text-[15px] font-normal mt-5">
            Crafting Dynamic Digital Experiences Tailored to Your Unique Vision
            and Goals.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/react.png" alt="" class="h-5" />
            <img src="../assets/img/vue.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/php.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/nest.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/laravel.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Web Design  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="webDesign"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="webDesign = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">Web Design</div>
          <div class="text-[15px] font-normal mt-5">
            Bringing Your Vision to Life Through Stunning and User-Friendly Web
            Design Solutions.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/figma.png" alt="" class="h-5" />
            <img src="../assets/img/sketch.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/webflow.svg" alt="" class="h-5 mt-10" />
            <img src="../assets/img/nest.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/invision.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- System Administration  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="systemAd"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="systemAd = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">
            System Administration
          </div>
          <div class="text-[15px] font-normal mt-5">
            Streamlining Operations and Ensuring Peak Performance Through Expert
            System Administration.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/docker.png" alt="" class="h-5" />
            <img src="../assets/img/kuber.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/amazon.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/ansible.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/git.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- LMS Administration  -->
  <div
    class="bg-[#3B3737] bg-opacity-70 fixed inset-0 z-50 transition-transform transform hover:scale-105 block md:hidden"
    v-if="lmsAd"
  >
    <div class="flex justify-center items-center h-screen relative">
      <div class="bg-white w-[400px] h-auto fixed top-[300px] rounded-[20px]">
        <div class="top-[15px] right-[15px] absolute">
          <button @click="lmsAd = false">
            <img src="../assets/img/close.png" alt="" class="w-7 h-7" />
          </button>
        </div>
        <div class="m-8">
          <div class="text-[20px] font-semibold mt-[50px]">
            LMS Administration
          </div>
          <div class="text-[15px] font-normal mt-5">
            Empowering Seamless Learning Experiences Through Expert Management
            of Your Learning Management System.
          </div>
          <div class="text-[15px] font-normal mt-10">Tools Used:</div>
          <!-- IMAGES LOGO  -->
          <div class="columns-3 space-x-4 space-y-4 mt-5">
            <img src="../assets/img/moodle.png" alt="" class="h-5" />
            <img src="../assets/img/canvas.png" alt="" class="h-3 mt-5" />
            <img src="../assets/img/blac.png" alt="" class="h-5 mt-10" />
            <img src="../assets/img/talents.png" alt="" class="h-5 mt-5" />
            <img src="../assets/img/cast.png" alt="" class="h-5 mt-5" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="relative w-full h-[50px] mt-10 block md:hidden">
    <img
      src="../assets/img/footer.png"
      alt="No Image"
      class="w-full h-full object-cover"
    />
    <div
      class="absolute inset-0 flex items-center justify-center text-white text-sm"
    >
      My Portfolio - Designed by Jayne Adil. All Rights Reserved.
    </div>
  </div> -->
</template>
<script>
import wepropel from "../assets/img/wepropel.png";
import excess from "../assets/img/excess.png";
import alorica from "../assets/img/alorica.png";
import tmobile from "../assets/img/tmobile.png";
import acc from "../assets/img/acc.png";
export default {
  name: "PortfolioPage",
  data() {
    return {
      scrolled: false,
      showFirst: true,
      showThird: true,
      showSecond: true,
      project: false,
      admin: false,
      customer: false,
      chat: false,
      lead: false,
      sales: false,
      activeTab: null,
      social: false,
      market: false,
      book: false,
      transaction: false,
      dataEntry: false,
      search: false,
      human: false,
      trans: false,
      systemAd: false,
      lmsAd: false,
      webDevelop: false,
      webDesign: false,
      // MEDIA DEVICE
      project1: false,
      admin1: false,
      customer1: false,
      chat1: false,
      lead1: false,
      sales1: false,
      activeTab1: null,
      social1: false,
      marke1t: false,
      book1: false,
      transaction1: false,
      dataEntry1: false,
      search1: false,
      human1: false,
      trans1: false,
      systemAd1: false,
      lmsAd1: false,
      webDevelop1: false,
      webDesign1: false,
      review1: true,
      review2: true,
      review3: true,
      activeTabReview: null,
      images: [wepropel, excess, alorica, tmobile, acc],
      currentIndex: 0,
      imageWidth: 200, // Assuming each image has a width of 150px
      interval: null,
    };
  },
  computed: {
    carouselStyle() {
      return {
        transform: `translateX(-${this.currentIndex * this.imageWidth}px)`,
        transition: "transform 0.5s ease-in-out",
      };
    },
  },
  mounted() {
    this.startCarousel();
    window.addEventListener("scroll", this.handleScroll);
    this.changeFavicon("../assets/img/logo.png"); // Change the favicon when the component is mounted
  },
  methods: {
    startCarousel() {
      this.interval = setInterval(() => {
        this.nextImage();
      }, 3000); // Change image every 3 seconds
    },
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      } else {
        this.currentIndex = 0;
      }
    },
    scrollToHome() {
      // Scroll to the element with ID 'second'
      document.getElementById("home").scrollIntoView({ behavior: "smooth" });
    },
    scrollToAbout() {
      // Scroll to the element with ID 'second'
      document.getElementById("about").scrollIntoView({ behavior: "smooth" });
    },
    scrollToService() {
      // Scroll to the element with ID 'second'
      document.getElementById("service").scrollIntoView({ behavior: "smooth" });
    },
    scrollToReviews() {
      // Scroll to the element with ID 'second'
      document.getElementById("reviews").scrollIntoView({ behavior: "smooth" });
    },
    scrollToContacts() {
      // Scroll to the element with ID 'second'
      document
        .getElementById("contacts")
        .scrollIntoView({ behavior: "smooth" });
    },
    changeFavicon(src) {
      const link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = src;
      document.getElementsByTagName("head")[0].appendChild(link);
    },

    transcription() {
      this.trans = true;
    },
    humanResource() {
      this.human = true;
    },
    research() {
      this.search = true;
    },
    transactionCoor() {
      this.transaction = true;
    },
    bookeeping() {
      this.book = true;
    },

    webDes() {
      this.webDesign = true;
    },
    webDev() {
      this.webDevelop = true;
    },
    lmsAdmin() {
      this.lmsAd = true;
    },
    systemAdmin() {
      this.systemAd = true;
    },
    dataEn() {
      this.dataEntry = true;
    },
    marketing() {
      this.market = true;
    },
    socialMed() {
      this.social = true;
    },
    projectManage() {
      this.project = true;
    },
    adminAss() {
      this.admin = true;
    },
    customerSup() {
      this.customer = true;
    },
    chatSupp() {
      this.chat = true;
    },
    leadGen() {
      this.lead = true;
    },
    Sales() {
      this.sales = true;
    },

    // MEDIA DEVICE
    transcription1() {
      this.trans1 = true;
    },
    humanResource1() {
      this.human1 = true;
    },
    research1() {
      this.search1 = true;
    },
    transactionCoor1() {
      this.transaction1 = true;
    },
    bookeeping1() {
      this.book1 = true;
    },
    webDes1() {
      this.webDesign1 = true;
    },
    webDev1() {
      this.webDevelop1 = true;
    },
    lmsAdmin1() {
      this.lmsAd1 = true;
    },
    systemAdmin1() {
      this.systemAd1 = true;
    },
    dataEn1() {
      this.dataEntry1 = true;
    },
    marketing1() {
      this.market1 = true;
    },
    socialMed1() {
      this.social1 = true;
    },
    projectManage1() {
      this.project1 = true;
    },
    adminAss1() {
      this.admin1 = true;
    },
    customerSup1() {
      this.customer1 = true;
    },
    chatSupp1() {
      this.chat1 = true;
    },
    leadGen1() {
      this.lead1 = true;
    },
    Sales1() {
      this.sales1 = true;
    },
    // END MEDIA DEVICE
    activeReviews1(tab) {
      this.activeTabReview = tab;
      this.review1 = true;
      this.review2 = true;
    },
    activeReviews2(tab) {
      this.activeTabReview = tab;
      this.review2 = false;
      this.review3 = true;
      this.review1 = false;
    },
    activeReviews3(tab) {
      this.activeTabReview = tab;
      this.review2 = true;
      this.review1 = false;
      this.review3 = false;
    },
    activateTab1(tab) {
      this.showFirst = true;
      this.showSecond = true;
      this.showThird = true;
      this.activeTab = tab;
    },
    activateTab2(tab) {
      this.showFirst = false;
      this.showThird = true;
      this.showSecond = false;
      this.activeTab = tab;
    },
    activateTab3(tab) {
      this.showThird = false;
      this.showFirst = false;
      this.showSecond = true;
      this.activeTab = tab;
    },
    handleScroll() {
      // Calculate scroll position
      const scrollPosition = window.scrollY || window.pageYOffset;

      // Check if scrolled down enough to trigger animation
      if (scrollPosition > 100) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
  },
};
</script>

<style>
.hover-underline::after {
  content: "";
  display: block;
  height: 3px; /* Adjust the thickness of the underline */
  background-color: white; /* Adjust the color of the underline */
  transition: width 0.3s ease-in-out; /* Animation effect */
  width: 0;
  margin-top: 2px; /* Adjust the distance from the text */
}
.hover-underline:hover::after {
  width: 100%;
}

.transition-up {
  transition: transform 0.6s ease-in-out;
  transform: translateY(-50%);
}
.transition-about {
  transition: transform 0.6s ease-in-out;
  transform: translateY(-10%);
}

.transition-img {
  transition: transform 0.6s ease-in-out;
  transform: translateY(-50%);
}

.transition-text {
  transition: transform 0.6s ease-in-out;
  transform: translateX(-20%);
}

.transition-down {
  transition: transform 1s ease-in-out;
  transform: translateY(5%);
}
@keyframes dropDown {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
